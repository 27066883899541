import React, { useEffect } from 'react'; // Import React and useEffect
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles from Material-UI
import failedIcon from '../../images/failed.svg';

const PaymentFailed = () => {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
    text: {
      fontSize: '25px',
      fontWeight: 700,
      marginTop: '50px',
      color: 'red',
    },
  }));

  const styles = useStyles();

  useEffect(() => {
    console.log('payment failed');
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <img src={failedIcon} alt="failed icon" />
      </div>
      <div className={styles.text}>Payment failure</div>
    </div>
  );
};

export default PaymentFailed; // Export the component
